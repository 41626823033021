<template>
  <section class="all-documents-view">
    <el-row type="flex" justify="center" align="center">
      <el-dialog
        :visible.sync="documentActionModal"
        width="30%"
        class="doc-modal"
        v-loading="modalLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <span slot="footer" class="dialog-footer">
          <el-button @click="documentActionModal = false">Cancel</el-button>
          <el-button type="primary">Confirm</el-button>
        </span>
      </el-dialog>
      <el-col v-if="!getIsMobile" :xl="4" :lg="2" :md="6" :sm="12" :xs="12">
        <el-col :span="22">
          <div class="mt-1">
            <h3 class="fw-medium blue-dark mb-2 fs-6 ml-05">
              {{ "Contacts" | getMenuTitle }}
            </h3>
          </div>
        </el-col>
      </el-col>
      <el-col :span="24">
        <div class="d-flex mb-4 top-search-condition-bar-type-two">
          <div class="actions-wrapper contacts-page">
            <div class="first-line">
              <label
                :data-state="state"
                for="search"
                class="search-icon-wrapper"
                :style="getIsMobile ? { marginTop: '6px' } : ''"
              >
                <input
                  v-model="search_string"
                  type="text"
                  @click="state = 'opan'"
                  @blur="state = 'close'"
                  @input="updateInput"
                />
                <i
                  class="el-icon-search"
                >
                </i>
              </label>

              <el-tooltip
                class="el-tooltips"
                placement="top-start"
                content="Create contact type"
              >
                <a @click="goToCreateContactType" v-if="!getIsMobile">
                  <el-button
                    plain
                    size="mini"
                    v-if="
                      this.getActiveWorkspace &&
                      this.getActiveWorkspace.plan_type &&
                      this.getActiveWorkspace.plan_type === 'BUSINESS'
                    "
                  >
                    <i class="el-icon-circle-plus-outline"></i> </el-button
                ></a>
                <a @click="goToCreateContactType" v-if="getIsMobile">
                  <el-button
                    plain
                    size="medium"
                    v-if="
                      this.getActiveWorkspace &&
                      this.getActiveWorkspace.plan_type &&
                      this.getActiveWorkspace.plan_type === 'BUSINESS'
                    "
                  >
                  <i class="el-icon-circle-plus-outline"></i>
                  </el-button></a
                >
              </el-tooltip>
              <!-- </el-tooltip> -->
            </div>

            <div class="second-line">
              <el-select
                v-model="filter_contact_type"
                :placeholder="
                  getIsMobile ? 'Select Type' : 'Select Contact Type'
                "
                filterable
                clearable
                default-first-option
                @change="onChangeFilterContactType"
                class="filter-contact-type"
              >
                <!-- :disabled="$route.name != 'edit-configure-document-image'" -->
                <!-- <el-option
                    :key="0"
                    :value="All"
                    :label="All"
                >
                  All
                </el-option> -->
                <el-option
                  v-for="(type, index) in getAllContactTypes"
                  :key="index + 1"
                  :value="type.name"
                  :label="type.name"
                >
                  {{ type.name }}
                </el-option>
              </el-select>

              <el-dropdown
                v-if="getAccess('documents', 'importContacts', '')  && checkPermissionByPermissionName('createContact')"
              >
                <el-tooltip content="Import Contacts" placement="top-start">
                  <a v-if="!getIsMobile">
                    <el-button class="el-dropdown-link" style="height:32px !important;">
                      <span class="create-contact">
                      Import <i class="el-icon-download"></i>
                    </span>
                    </el-button>
                  </a>
                  <a v-if="getIsMobile">
                    <el-button size="medium" class="el-dropdown-link">
                      {{ getIsMobile ? "Import" : "" }}
                      <i v-if="!getIsMobile" class="el-icon-download"></i>
                    </el-button>
                  </a>
                </el-tooltip>
                <el-dropdown-menu
                  slot="dropdown"
                  class="import-dropdown-btn-menu"
                >
                  <a @click="goToImportGmailContact">
                    <el-dropdown-item>
                      <img
                        src="@/assets/img/gmail.svg"
                        alt="icon"
                        class="icon-type-one gmail"
                      />
                      <span>Import from Gmail</span>
                    </el-dropdown-item>
                  </a>
                  <a @click="goToImportSpreadsheetContact">
                    <el-dropdown-item>
                      <img
                        src="@/assets/img/google_sheets.svg"
                        alt="icon"
                        class="icon-type-one gmail"
                      />
                      <span>Import from Spreadsheet</span>
                    </el-dropdown-item>
                  </a>
                  <a @click="goToImportOutlookContact">
                    <el-dropdown-item>
                      <img
                        src="@/assets/img/outlook.svg"
                        alt="icon"
                        class="icon-type-one gmail"
                      />
                      <span>Import from Outlook</span>
                    </el-dropdown-item>
                  </a>
                </el-dropdown-menu>
              </el-dropdown>

              <el-button
                v-if="checkPermissionByPermissionName('createContact')"
                class="create-btn px-2"
                type="primary"
                @click="goToCreateContact"
                :style="getIsMobile ? 'width:60px' : 'height:32px'"
              >
              <span class="create-contact">
              <i class="fa fa-plus-square-o"></i>
                {{ getIsMobile ? " " : "Create Contact" }}
              </span>
              </el-button>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="contacts-list">
      <el-col v-if="!getIsMobile" :span="2" class="mb-05">
        <div>
          <el-dropdown
            class="import-dropdown-btn"
            v-if="this.selectedContact.length"
          >
            <el-button size="mini">
              Delete
              <i class="el-icon-arrow-down"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <a @click="bulkDelete()">
                <el-dropdown-item>
                  <i class="el-icon-delete"></i>
                  Delete
                </el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
      <el-col :span="24">
        <div
          v-loading="loading"
          class="vue-data-table-default"
          element-loading-text="Loading..."
        >
          <data-tables-server
            v-loading="configureLoading"
            :fullscreen="true"
            :data="data"
            :total="total"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :pagination-props="paginationProps"
            @query-change="loadData"
            @sort-change="sortChange"
            style="width: 100%"
            v-if="!getIsMobile"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>

            <el-table-column
              label="Name"
              id="name"
              prop="first_name"
              min-width="250"
              sortable="custom"
              fixed="left"
            >
              <template slot-scope="scope">
                <div class="icon-block initial">
                  <div class="icon avatar-icon-in-table">
                    <div class="initial-text">
                      <span>{{ scope.row | getUserNameAvatar }}</span>
                    </div>
                  </div>
                  <div class="icon-text">
                    {{ scope.row | getUserFullName }}
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="Type" width="250">
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.contact_types &&
                    scope.row.contact_types.length &&
                    scope.row.contact_types[0].contact_type &&
                    scope.row.contact_types[0].contact_type.name
                  "
                >
                  <!-- <el-tag
                    v-for="(ct, i) in scope.row.contact_types"
                    :key="i + scope.row._id"
                    :type="ct.contact_type && ct.contact_type.entity_type == 'BUSINESS' ? 'success' : ''"
                  >{{ct.contact_type.name}}
                  </el-tag> -->
                  <el-tag
                    :type="
                      scope.row &&
                      scope.row.contact_types &&
                      scope.row.contact_types[0] &&
                      scope.row.contact_types[0].contact_type &&
                      scope.row.contact_types[0].contact_type.name &&
                      scope.row.contact_types[0].contact_type.entity_type ==
                        'BUSINESS'
                        ? 'success'
                        : ''
                    "
                  >
                    {{ scope.row.contact_types[0].contact_type.name }}
                  </el-tag>
                  <el-popover trigger="hover" v-if="scope.row.contact_types">
                    <div
                      v-for="(ct, i) in scope.row.contact_types"
                      :key="i + scope.row._id"
                      :type="
                        ct.contact_type &&
                        ct.contact_type.entity_type == 'BUSINESS'
                          ? 'success'
                          : ''
                      "
                    >
                      <p
                        style="font-weight: bold"
                        v-if="ct && ct.contact_type && ct.contact_type.name"
                      >
                        {{ i + 1 }}.{{ ct.contact_type.name }}
                      </p>
                    </div>
                    <el-tag
                      v-if="scope.row.contact_types.length > 1"
                      slot="reference"
                      style="
                        border-radius: 50% !important;
                        margin-left: 5px !important;
                      "
                    >
                      {{ scope.row.contact_types.length - 1 + "+" }}
                    </el-tag>
                  </el-popover>
                </div>
                <div
                  v-else-if="
                    scope.row.contact_type && scope.row.contact_type.name
                  "
                >
                  <el-tag
                    :type="
                      scope.row.contact_type &&
                      scope.row.contact_type.entity_type == 'BUSINESS'
                        ? 'success'
                        : ''
                    "
                  >
                    {{ scope.row.contact_type.name }}
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="email"
              label="Email"
              sortable="custom"
              width="280"
            ></el-table-column>
            <el-table-column
              :default-sort="{ prop: 'phone_number', order: 'descending' }"
              prop="phone_number"
              label="Phone"
              sortable
              width="150"
            ></el-table-column>
            <el-table-column
              prop="address"
              label="Address"
              sortable="custom"
              width="200"
            ></el-table-column>
            <el-table-column
              prop="title"
              id="title"
              sortable="custom"
              label="Title"
              width="200"
            ></el-table-column>
            <el-table-column
              prop="created_at"
              label="Created at"
              width="200"
              sortable
            >
              <template slot-scope="scope"
                >{{ scope.row.created_at | globalDateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              prop="updated_at"
              label="Last Modified"
              width="200"
              sortable
              :sort-method="updatedDateSort"
            >
              <template slot-scope="scope"
                >{{ scope.row.updated_at | globalDateFormat }}
              </template>
            </el-table-column>
            <template v-if="getUserType">
              <el-table-column
                prop="created_by"
                label="Created By"
                sortable
                width="150"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.created_by && scope.row.created_by._id">
                    {{
                      getAuthenticatedUser._id.toString() ==
                      scope.row.created_by._id.toString()
                        ? "SELF"
                        : scope.row.created_by.first_name +
                          " " +
                          scope.row.created_by.last_name
                    }}
                  </div>
                </template>
              </el-table-column>
            </template>

            <el-table-column
              prop="action"
              label="Actions"
              class="action"
              align="center"
              width="200"
              fixed="right"
              v-if="
                checkPermissionByPermissionName('viewContactDocuments') ||
                checkPermissionByPermissionName('editContact') ||
                checkPermissionByPermissionName('deleteContact')
              "
            >
              <template slot-scope="scope">
                <ul class="action-buttons" align="center">
                  <li
                    v-if="
                      checkPermissionByPermissionName('viewContactDocuments') ||
                      (scope.row.created_by &&
                        scope.row.created_by._id &&
                        getAuthenticatedUser._id.toString() ==
                          scope.row.created_by._id.toString())
                    "
                    class="first-button"
                  >
                    <el-button
                      plain
                      size="mini"
                      type="primary"
                      class="px-2 ml-2"
                      title="View Document"
                      @click="goToDocuments(scope.row)"
                    >
                      <i class="el-icon-document"></i>
                    </el-button>
                  </li>
                  <li
                    v-if="
                      checkPermissionByPermissionName('editContact') ||
                      (scope.row.created_by &&
                        scope.row.created_by._id &&
                        getAuthenticatedUser._id.toString() ==
                          scope.row.created_by._id.toString())
                    "
                    class="second-button"
                  >
                    <el-button
                      plain
                      type="warning"
                      size="mini"
                      class="px-2 ml-2"
                      @click="onEdit(scope.row)"
                      title="Edit Contact"
                    >
                      <i class="el-icon-edit"></i>
                    </el-button>
                  </li>
                  <li
                    v-if="
                      checkPermissionByPermissionName('deleteContact') ||
                      (scope.row.created_by &&
                      scope.row.created_by._id &&
                      getAuthenticatedUser._id.toString() ==
                        scope.row.created_by._id
                        ? scope.row.created_by._id.toString()
                        : scope.row.created_by.toString())
                    "
                    class="third-button"
                  >
                    <template
                      v-if="
                        getAuthenticatedUser &&
                        scope.row.created_by &&
                        getAuthenticatedUser._id &&
                        scope.row.created_by &&
                        scope.row.created_by._id &&
                        (getAuthenticatedUser._id.toString() ==
                          scope.row.created_by._id.toString() ||
                          getActiveWorkspace.company_id.toString() ==
                            scope.row.company_id.toString())
                      "
                    >
                      <el-button
                        plain
                        type="danger"
                        size="mini"
                        class="px-2 ml-2"
                        @click="onDelete(scope.row)"
                      >
                        <i class="el-icon-delete"></i>
                      </el-button>
                    </template>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </data-tables-server>

          <data-tables-server
            v-loading="configureLoading"
            :fullscreen="true"
            :data="data"
            :total="total"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :pagination-props="paginationProps"
            @query-change="loadData"
            style="width: 100%"
            v-if="getIsMobile"
            class="contacts-list-table"
          >
            <el-table-column type="expand">
              <template slot-scope="scope">
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Type:</div>
                  <div class="expand-column-item-content">
                    {{ scope.row.contact_type.name }}
                  </div>
                </div>
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Email:</div>
                  <div class="expand-column-item-content">
                    {{ scope.row.email }}
                  </div>
                </div>
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Phone:</div>
                  <div class="expand-column-item-content">
                    {{ scope.row.phone_number }}
                  </div>
                </div>
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Address:</div>
                  <div class="expand-column-item-content">
                    {{ scope.row.address }}
                  </div>
                </div>
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Last Modified:</div>
                  <div class="expand-column-item-content">
                    {{ scope.row.updated_at }}
                  </div>
                </div>
                <template
                  v-if="
                    getUserType &&
                    (getUserType.name == 'ADMIN' || getUserType.name == 'OWNER')
                  "
                >
                  <div class="expand-column-item">
                    <div class="expand-column-item-label">Created By:</div>
                    <div class="expand-column-item-content">
                      {{
                        scope.row.created_by.first_name +
                        " " +
                        scope.row.created_by.last_name
                      }}
                    </div>
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="Name" id="name" fixed="left">
              <template slot-scope="scope">
                <div class="icon-block initial">
                  <div class="icon avatar-icon-in-table">
                    <div class="initial-text">
                      <span>{{ scope.row | getUserNameAvatar }}</span>
                    </div>
                  </div>
                  <div class="icon-text">
                    {{ scope.row | getUserFullName }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="action"
              label="Actions"
              class="action"
              align="right"
              fixed="right"
            >
              <template slot-scope="scope">
                <ul class="action-buttons" style="flex-direction: column">
                  <li
                    v-if="
                      checkPermissionByPermissionName('viewContactDocuments')
                    "
                    class="first-button"
                  >
                    <el-button
                      plain
                      size="mini"
                      type="primary"
                      class="px-2 ml-2"
                      title="View Document"
                      @click="goToDocuments(scope.row)"
                    >
                      <i class="el-icon-document"></i>
                    </el-button>
                  </li>
                  <li
                    v-if="checkPermissionByPermissionName('editContact')"
                    class="second-button"
                  >
                    <el-button
                      plain
                      type="warning"
                      size="mini"
                      class="px-2 ml-2"
                      @click="onEdit(scope.row)"
                      title="Edit Contact"
                    >
                      <i class="el-icon-edit"></i>
                    </el-button>
                  </li>
                  <li
                    v-if="
                      checkPermissionByPermissionName('deleteContact') ||
                      (scope.row.created_by &&
                      getAuthenticatedUser._id.toString() ==
                        scope.row.created_by._id
                        ? scope.row.created_by._id.toString()
                        : scope.row.created_by.toString())
                    "
                    class="third-button"
                  >
                    <template
                      v-if="
                        getAuthenticatedUser._id &&
                        scope.row.created_by &&
                        scope.row.created_by._id &&
                        (getAuthenticatedUser._id.toString() ==
                          scope.row.created_by._id.toString() ||
                          getUserType.name == 'ADMIN' ||
                          getUserType.name == 'OWNER')
                      "
                    >
                      <el-button
                        plain
                        type="danger"
                        size="mini"
                        class="px-2 ml-2"
                        @click="onDelete(scope.row)"
                      >
                        <i class="el-icon-delete"></i>
                      </el-button>
                    </template>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </data-tables-server>
        </div>
      </el-col>
    </el-row>

    <!-- {{  outlookUsersData}}` -->

    <el-dialog
      class="DialogueStyle"
      title="Warning"
      :visible.sync="centerDialogVisible"
      width="27%"
      center
    >
      <span v-if="this.selectedContact.length == 1"
        >Are you sure you want to delete 1 selected Contact?</span
      >
      <span
        v-if="
          this.selectedContact.length == 0 || this.selectedContact.length > 1
        "
        >Are you sure you want to delete
        {{ this.selectedContact.length }} selected Contacts?</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="selectedContactDelete"
          >Confirm</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="Import Contacts From Gmail"
      :visible.sync="showImportContactsPopup"
      :width="getIsMobile ? '100%' : '60%'"
      class="doc-modal import-contacts-popup"
      :show-close="true"
      :before-close="handleClosePopup"
    >
      <!-- :before-close="resetBulkModal" -->
      <div>
        <el-tabs v-model="activeNameForImport">
          <el-tab-pane
            :label="`All Contacts ${successfullCSVData.length}`"
            name="success"
          >
            <el-scrollbar wrap-style="">
              <div class="import-contacts-table-wrapper">
                <el-table
                  :data="getCurrentPageOfSuccessfullCSVData"
                  style="width: 100%"
                  @cell-mouse-enter="editSuccessCSVData"
                  @cell-mouse-leave="resetSuccessCSVData"
                  row-key="id"
                  class="import-contacts-table"
                  ref="multipleTableOfSpreadSheet"
                >
                  <el-table-column
                    v-for="(row, index) in primaryHeaders"
                    :key="index"
                    :label="row"
                    width="220"
                  >
                    <template slot-scope="scope">
                      <div
                        v-if="currentActiveSuccessRowIndex == scope.row['id']"
                      >
                        <el-input
                          size="mini"
                          v-model="scope.row[row]"
                          v-if="row != 'contact_type'"
                        ></el-input>
                        <el-select
                          v-model="scope.row[row]"
                          placeholder="Select contact type"
                          filterable
                          default-first-option
                          class="filter-contact-type"
                          v-if="row == 'contact_type'"
                        >
                          <el-option
                            v-for="(type, index) in getAllContactTypes"
                            :key="index + 1"
                            :value="type._id"
                            :label="type.name"
                          >
                            {{ type.name }}
                          </el-option>
                        </el-select>
                      </div>
                      <div v-else>
                        <!-- {{ scope.row[row] }} -->
                        <p v-if="row == 'contact_type'">
                         {{ getContactName(scope.row[row]) }}
                        </p>
                        <p v-else>
                          {{ scope.row[row] }}
                        </p>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-scrollbar>
            <el-pagination
              :current-page.sync="currentPageForIC"
              :page-sizes="paginationPropsForIC"
              :page-size="pageSizeForIC"
              layout="prev, pager, next, jumper, total"
              :total="totalForIC"
            ></el-pagination>
            <!-- layout="prev, pager, next, jumper, sizes, total" -->
          </el-tab-pane>
          <el-tab-pane
            :label="`Errors ${errorfullCSVData.length}`"
            name="error"
          >
            <el-scrollbar wrap-style="">
              <div class="import-contacts-table-wrapper">
                <el-table
                  :data="getCurrentPageOfErrorfullCSVData"
                  style="width: 100%"
                  @cell-mouse-enter="editErrorCSVData"
                  @cell-mouse-leave="resetErrorCSVData"
                  row-key="id"
                  class="import-contacts-table"
                >
                  <el-table-column
                    v-for="(row, index) in primaryHeaders"
                    :key="index"
                    :label="row"
                    width="220"
                  >
                    <template slot-scope="scope">
                      <div v-if="currentActiveRowIndex == scope.row['id']">
                        <el-input
                          size="mini"
                          v-model="scope.row[row]"
                          v-if="row != 'contact_type'"
                        ></el-input>
                        <el-select
                          v-model="scope.row[row]"
                          placeholder="Select contact type"
                          filterable
                          default-first-option
                          class="filter-contact-type"
                          v-if="row == 'contact_type'"
                        >
                          <el-option
                            v-for="(type, index) in getAllContactTypes"
                            :key="index + 1"
                            :value="type._id"
                            :label="type.name"
                          >
                            {{ type.name }}
                          </el-option>
                        </el-select>
                      </div>
                      <div v-else>
                        <!-- {{ scope.row[row] }}
                         -->
                        <p
                          v-if="
                            scope.row[row] &&
                            scope.row[row].length &&
                            row == 'phone' &&
                            !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(
                              scope.row[row]
                            ) &&
                            scope.row[row].length >= 1
                          "
                          style="color: red"
                        >
                          Invalid phone
                        </p>
                        <p
                          v-else-if="
                            scope.row[row] &&
                            scope.row[row].length &&
                            row == 'company'
                          "
                        >
                          {{ scope.row[row] }}
                        </p>
                        <p
                          v-else-if="
                            scope.row[row] &&
                            scope.row[row].length &&
                            row == 'address'
                          "
                        >
                          {{ scope.row[row] }}
                        </p>
                        <p
                          v-else-if="
                            scope.row[row] &&
                            scope.row[row].length &&
                            (row == 'first_name' || row == 'last_name') &&
                            (scope.row[row].length < 1 ||
                              !checkNameValidation(scope.row[row]))
                          "
                          style="color: red"
                        >
                          Invalid string or less than 1
                        </p>
                        <p
                          v-else-if="
                            scope.row[row] &&
                            scope.row[row].length &&
                            row == 'email' &&
                            !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
                              scope.row[row]
                            ) &&
                            scope.row[row].length >= 1
                          "
                          style="color: red"
                        >
                          Invalid email
                        </p>
                        <p
                          v-else-if="
                            scope.row[row] &&
                            scope.row[row].length &&
                            row == 'phone_number' &&
                            !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(
                              scope.row[row]
                            ) &&
                            scope.row[row].length >= 1
                          "
                          style="color: red"
                        >
                          Invalid Phone Number
                        </p>
                        <p v-else-if="scope.row[row] && scope.row[row].length">
                          <span v-if="row == 'contact_type'">
                            {{ getContactName(scope.row[row]) }}
                          </span>
                          <span v-else>
                            {{ scope.row[row] }}
                          </span>
                        </p>
                        <p
                          v-else-if="
                            (row == 'first_name' && !scope.row[row]) ||
                            (row == 'last_name' && !scope.row[row]) ||
                            (row == 'email' && !scope.row[row])
                          "
                          style="color: red"
                        >
                          Missing Value
                        </p>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-scrollbar>
            <el-pagination
              :current-page.sync="currentPageForICError"
              :page-sizes="paginationPropsForICError"
              :page-size="pageSizeForICError"
              layout="prev, pager, next, jumper, total"
              :total="totalForICError"
            ></el-pagination>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" v-if="uploadedCSVDataResult.length">
        <!-- <el-button @click="resetCSVData">Re-Upload CSV</el-button> -->
        <el-button
          type="danger"
          :disabled="successfullCSVData && !successfullCSVData.length"
          @click="importContacts"
        >
          Import Contacts
        </el-button>
      </span>
    </el-dialog>

    <!-- :before-close="resetBulkModal" -->
    <dialog-component
      :title="'Import Contacts from Spreadsheet'"
      :visible="importFromSSActionModal"
      :containerWidth="getIsMobile ? '100%' : '60%'"
      class="doc-modal"
      :before-close="handleClosePopup"
    >
      <div v-if="viewImportingContactsResultPopup">
        <el-row :gutter="30">
          <el-col :lg="{ span: 23, offset: 0 }">
            <div class="result-row">
              <img
                alt="Document Sent"
                src="@/assets/img/workflow-sent-success.gif"
              />
              <h3>{{ importSuccessMessage }}</h3>
            </div>
            <!-- <div class="result-row">
              <span class="title bold larger-font-size">
                Total imported contacts are:
              </span>
              <span class="value">
                {{ importTotalContactsCnt }}
              </span>
            </div>
            <div class="result-row indent">
              <span class="title"> Contacts added: </span>
              <span class="value">
                {{ importAddedContactsCnt }}
              </span>
            </div>
            <div class="result-row indent">
              <span class="title"> Contacts updated: </span>
              <span class="value">
                {{ importUpdatedContactsCnt }}
              </span>
            </div>
            <div class="result-row failed indent">
              <span class="title"> Contacts failed: </span>
              <span class="value">
                {{ importFailedContactsCnt }}
              </span>
            </div>
            <div
              class="error-details indent"
              v-for="item in importErrorDetails"
              :key="item"
              :value="item"
            >
              {{ item }}
            </div> -->
          </el-col>
        </el-row>
      </div>
      <div v-else>
        <div>
          <el-steps
            :space="300"
            :active="activeStep"
            finish-status="success"
            align-center
            v-if="getBulkSendPercentage != 100 && !isContactsCreated"
            class="bulk-step"
          >
            <el-step title="Create CSV"> </el-step>
            <el-step title="Upload your CSV"> </el-step>
          </el-steps>
          <el-divider></el-divider>
          <div v-if="activeStep == 0">
            <div style="display: flex" class="download-csv-button-wrapper">
              <p>
                Here is a CSV format to use. Download it and fill with your
                data.
              </p>
              <el-button
                size="mini"
                @click="generateCSVFile"
                class="download-csv ml-3"
                >Download Blank CSV</el-button
              >
            </div>
          </div>
          <div v-if="activeStep == 1" style="text-align: center">
            <div v-if="!uploadedCSVDataResult.length">
              <el-upload
                class="upload-csv-file"
                drag
                name="logo"
                :on-change="uploadBulkSendCSV"
                action
                :show-file-list="false"
                :auto-upload="false"
                accept=".csv"
              >
                <div class="upload-file">
                  <div class="mt-2">
                    <img
                      src="@/assets/img/icons/upload-file.svg"
                      alt="Upload File"
                      height="30"
                    />
                  </div>
                  <p>Drag &amp; Drop CSV file here</p>
                  <el-button
                    size="mini"
                    style="
                      background-color: transparent;
                      border-color: #f754a2;
                      color: #f754a2;
                      border-radius: 0;
                    "
                    >Import Contacts List</el-button
                  >
                </div>
              </el-upload>
            </div>
            <div v-else>
              <el-tabs v-model="activeNameForImport">
                <el-tab-pane
                  :label="`All Contacts ${successfullCSVData.length}`"
                  name="success"
                >
                  <el-scrollbar wrap-style="">
                    <div class="import-contacts-table-wrapper">
                      <el-table
                        :data="getCurrentPageOfSuccessfullCSVData"
                        style="width: 100%"
                        @cell-mouse-enter="editSuccessCSVData"
                        @cell-mouse-leave="resetSuccessCSVData"
                        row-key="id"
                        class="import-contacts-table"
                      >
                        <el-table-column
                          v-for="(row, index) in primaryHeaders"
                          :key="index"
                          :label="row"
                          width="220"
                        >
                          <template slot-scope="scope">
                            <div
                              v-if="
                                currentActiveSuccessRowIndex == scope.row['id']
                              "
                            >
                              <el-select
                                v-model="scope.row[row]"
                                placeholder="Select contact type"
                                filterable
                                default-first-option
                                class="filter-contact-type"
                                v-if="row == 'contact_type'"
                                size="mini"
                              >
                                <el-option
                                  v-for="(type, index) in getAllContactTypes"
                                  :key="index + 1"
                                  :value="type._id"
                                  :label="type.name"
                                >
                                  {{ type.name }}
                                </el-option>
                              </el-select>
                              <el-input
                                size="mini"
                                v-model="scope.row[row]"
                                v-else
                              ></el-input>
                            </div>
                            <div v-else>
                              <p v-if="row == 'contact_type'">
                                {{ getContactName(scope.row[row]) }}
                              </p>
                              <p v-else>
                                {{ scope.row[row] }}
                              </p>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </el-scrollbar>
                  <el-pagination
                    @size-change="handleSizeChangeForIC"
                    @current-change="handleCurrentChangeForIC"
                    :current-page.sync="currentPageForIC"
                    :page-sizes="paginationPropsForIC"
                    :page-size="pageSizeForIC"
                    layout="prev, pager, next, jumper, total"
                    :total="totalForIC"
                  ></el-pagination>
                  <!-- layout="prev, pager, next, jumper, sizes, total" -->
                </el-tab-pane>
                <el-tab-pane
                  :label="`Errors ${errorfullCSVData.length}`"
                  name="error"
                >
                  <el-scrollbar wrap-style="">
                    <div class="import-contacts-table-wrapper">
                      <el-table
                        :data="getCurrentPageOfErrorfullCSVData"
                        style="width: 100%"
                        @cell-mouse-enter="editErrorCSVData"
                        @cell-mouse-leave="resetErrorCSVData"
                        row-key="id"
                        class="import-contacts-table"
                      >
                        <el-table-column
                          v-for="(row, index) in primaryHeaders"
                          :key="index"
                          :label="row"
                          width="220"
                        >
                          <template slot-scope="scope">
                            <div
                              v-if="currentActiveRowIndex == scope.row['id']"
                            >
                              <el-select
                                v-model="scope.row[row]"
                                placeholder="Select contact type"
                                filterable
                                default-first-option
                                class="filter-contact-type"
                                v-if="row == 'contact_type'"
                                size="mini"
                              >
                                <el-option
                                  v-for="(type, index) in getAllContactTypes"
                                  :key="index + 1"
                                  :value="type._id"
                                  :label="type.name"
                                >
                                  {{ type.name }}
                                </el-option>
                              </el-select>
                              <el-input
                                size="mini"
                                v-model="scope.row[row]"
                                v-else
                              ></el-input>
                            </div>
                            <div v-else>
                              <p
                                v-if="
                                  scope.row[row] &&
                                  scope.row[row].length &&
                                  row == 'phone' &&
                                  !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(
                                    scope.row[row]
                                  ) &&
                                  scope.row[row].length >= 1
                                "
                                style="color: red"
                              >
                                Invalid phone
                              </p>
                              <p
                                v-else-if="
                                  scope.row[row] &&
                                  scope.row[row].length &&
                                  row == 'company'
                                "
                              >
                                {{ scope.row[row] }}
                              </p>
                              <p
                                v-else-if="
                                  scope.row[row] &&
                                  scope.row[row].length &&
                                  row == 'address'
                                "
                              >
                                {{ scope.row[row] }}
                              </p>
                              <p
                                v-else-if="
                                  scope.row[row] &&
                                  scope.row[row].length &&
                                  (row == 'first_name' || row == 'last_name') &&
                                  (scope.row[row].length < 1 ||
                                    !checkNameValidation(scope.row[row]))
                                "
                                style="color: red"
                              >
                                Invalid string or less than 1
                              </p>
                              <p
                                v-else-if="
                                  scope.row[row] &&
                                  scope.row[row].length &&
                                  row == 'email' &&
                                  !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
                                    scope.row[row]
                                  ) &&
                                  scope.row[row].length >= 1
                                "
                                style="color: red"
                              >
                                Invalid email
                              </p>
                              <p
                                v-else-if="
                                  scope.row[row] &&
                                  scope.row[row].length &&
                                  row == 'phone_number' &&
                                  !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(
                                    scope.row[row]
                                  ) &&
                                  scope.row[row].length >= 1
                                "
                                style="color: red"
                              >
                                Invalid Phone Number
                              </p>
                              <p v-else-if="row == 'contact_type'">
                                {{ getContactName(scope.row[row]) }}
                              </p>
                              <p
                                v-else-if="
                                  scope.row[row] && scope.row[row].length
                                "
                              >
                                {{ scope.row[row] }}
                              </p>
                              <p
                                v-else-if="
                                  (row == 'first_name' && !scope.row[row]) ||
                                  (row == 'last_name' && !scope.row[row]) ||
                                  (row == 'email' && !scope.row[row])
                                "
                                style="color: red"
                              >
                                Missing Value
                              </p>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </el-scrollbar>
                  <el-pagination
                    :current-page.sync="currentPageForICError"
                    :page-sizes="paginationPropsForICError"
                    :page-size="pageSizeForICError"
                    layout="prev, pager, next, jumper, total"
                    :total="totalForICError"
                  ></el-pagination>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>
      <span
        slot="footer"
        v-if="activeStep == 0 && !viewImportingContactsResultPopup"
      >
        <el-button @click="closePopup">Cancel</el-button>
        <el-button type="primary" @click="activeStep = 1">Continue</el-button>
      </span>
      <span
        slot="footer"
        v-if="
          activeStep == 1 &&
          uploadedCSVDataResult.length &&
          !viewImportingContactsResultPopup
        "
      >
        <el-button @click="reuploadCSV">Re-Upload CSV</el-button>
        <el-button
          type="danger"
          :disabled="successfullCSVData && !successfullCSVData.length"
          @click="importContacts"
        >
          Import Contacts
        </el-button>
      </span>
      <span
        slot="footer"
        v-if="
          activeStep == 2 &&
          !isContactsCreated &&
          !docSettingsVisible &&
          !viewImportingContactsResultPopup
        "
      >
        <el-button @click="activeStep = 1">Back</el-button>
        <el-button
          type="danger"
          :loading="bulkSendBtnLoding"
          @click="checkSettingsVisibleOrNot"
          >{{ `Send documents` }}</el-button
        >
      </span>
    </dialog-component>

    <!-- import form outlook -->

    <el-dialog
      title="Import Contacts From Outlook"
      :visible.sync="showImportContactsOutlookPopup"
      :width="getIsMobile ? '100%' : '60%'"
      class="doc-modal import-contacts-popup"
      :show-close="true"
      :before-close="handleClosePopup"
    >
      <!-- :before-close="resetBulkModal" -->
      <div>
        <el-tabs v-model="activeNameForImport">
          <el-tab-pane
            :label="`All Contacts ${successfullCSVData.length}`"
            name="success"
          >
            <el-scrollbar wrap-style="">
              <div class="import-contacts-table-wrapper">
                <el-table
                  :data="getCurrentPageOfSuccessfullCSVData"
                  style="width: 100%"
                  @cell-mouse-enter="editSuccessCSVData"
                  @cell-mouse-leave="resetSuccessCSVData"
                  row-key="id"
                  class="import-contacts-table"
                  ref="multipleTableOfSpreadSheet"
                >
                  <el-table-column
                    v-for="(row, index) in primaryHeaders"
                    :key="index"
                    :label="row"
                    width="220"
                  >
                    <template slot-scope="scope">
                      <div
                        v-if="currentActiveSuccessRowIndex == scope.row['id']"
                      >
                        <el-input
                          size="mini"
                          v-model="scope.row[row]"
                          v-if="row != 'contact_type'"
                        ></el-input>
                        <el-select
                          v-model="scope.row[row]"
                          placeholder="Select contact type"
                          filterable
                          default-first-option
                          class="filter-contact-type"
                          v-if="row == 'contact_type'"
                        >
                          <el-option
                            v-for="(type, index) in getAllContactTypes"
                            :key="index + 1"
                            :value="type._id"
                            :label="type.name"
                          >
                            {{ type.name }}
                          </el-option>
                        </el-select>
                      </div>
                      <div v-else>
                        <!-- {{ scope.row[row] }} -->
                        <p v-if="row == 'contact_type'">
                          {{ getContactName(scope.row[row]) }}
                        </p>
                        <p v-else>
                          {{ scope.row[row] }}
                        </p>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-scrollbar>
            <el-pagination
              :current-page.sync="currentPageForIC"
              :page-sizes="paginationPropsForIC"
              :page-size="pageSizeForIC"
              layout="prev, pager, next, jumper, total"
              :total="totalForIC"
            ></el-pagination>
            <!-- layout="prev, pager, next, jumper, sizes, total" -->
          </el-tab-pane>
          <el-tab-pane
            :label="`Errors ${errorfullCSVData.length}`"
            name="error"
          >
            <el-scrollbar wrap-style="">
              <div class="import-contacts-table-wrapper">
                <el-table
                  :data="getCurrentPageOfErrorfullCSVData"
                  style="width: 100%"
                  @cell-mouse-enter="editErrorCSVData"
                  @cell-mouse-leave="resetErrorCSVData"
                  row-key="id"
                  class="import-contacts-table"
                >
                  <el-table-column
                    v-for="(row, index) in primaryHeaders"
                    :key="index"
                    :label="row"
                    width="220"
                  >
                    <template slot-scope="scope">
                      <div v-if="currentActiveRowIndex == scope.row['id']">
                        <el-input
                          size="mini"
                          v-model="scope.row[row]"
                          v-if="row != 'contact_type'"
                        ></el-input>
                        <el-select
                          v-model="scope.row[row]"
                          placeholder="Select contact type"
                          filterable
                          default-first-option
                          class="filter-contact-type"
                          v-if="row == 'contact_type'"
                        >
                          <el-option
                            v-for="(type, index) in getAllContactTypes"
                            :key="index + 1"
                            :value="type._id"
                            :label="type.name"
                          >
                            {{ type.name }}
                          </el-option>
                        </el-select>
                      </div>
                      <div v-else>
                        <!-- {{ scope.row[row] }} -->
                        <p
                          v-if="
                            scope.row[row] &&
                            scope.row[row].length &&
                            row == 'phone' &&
                            !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(
                              scope.row[row]
                            ) &&
                            scope.row[row].length >= 1
                          "
                          style="color: red"
                        >
                          Invalid phone
                        </p>
                        <p
                          v-else-if="
                            scope.row[row] &&
                            scope.row[row].length &&
                            row == 'company'
                          "
                        >
                          {{ scope.row[row] }}
                        </p>
                        <p
                          v-else-if="
                            scope.row[row] &&
                            scope.row[row].length &&
                            row == 'address'
                          "
                        >
                          {{ scope.row[row] }}
                        </p>
                        <p
                          v-else-if="
                            scope.row[row] &&
                            scope.row[row].length &&
                            (row == 'first_name' || row == 'last_name') &&
                            (scope.row[row].length < 1 ||
                              !checkNameValidation(scope.row[row]))
                          "
                          style="color: red"
                        >
                          Invalid string or less than 1
                        </p>
                        <p
                          v-else-if="
                            scope.row[row] &&
                            scope.row[row].length &&
                            row == 'email' &&
                            !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
                              scope.row[row]
                            ) &&
                            scope.row[row].length >= 1
                          "
                          style="color: red"
                        >
                          Invalid email
                        </p>
                        <p
                          v-else-if="
                            scope.row[row] &&
                            scope.row[row].length &&
                            row == 'phone_number' &&
                            !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(
                              scope.row[row]
                            ) &&
                            scope.row[row].length >= 1
                          "
                          style="color: red"
                        >
                          Invalid Phone Number
                        </p>
                        <p v-else-if="scope.row[row] && scope.row[row].length">
                          <span v-if="row == 'contact_type'">
                            {{ getContactName(scope.row[row]) }}
                          </span>
                          <span v-else>
                            {{ scope.row[row] }}
                          </span>
                        </p>
                        <p
                          v-else-if="
                            (row == 'first_name' && !scope.row[row]) ||
                            (row == 'last_name' && !scope.row[row]) ||
                            (row == 'email' && !scope.row[row])
                          "
                          style="color: red"
                        >
                          Missing Value
                        </p>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-scrollbar>
            <el-pagination
              :current-page.sync="currentPageForICError"
              :page-sizes="paginationPropsForICError"
              :page-size="pageSizeForICError"
              layout="prev, pager, next, jumper, total"
              :total="totalForICError"
            ></el-pagination>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" v-if="uploadedCSVDataResult.length">
        <!-- <el-button @click="resetCSVData">Re-Upload CSV</el-button> -->
        <el-button
          type="danger"
          :disabled="successfullCSVData && !successfullCSVData.length"
          @click="importContacts"
        >
          Import Contacts
        </el-button>
      </span>
    </el-dialog>
    
    <!-- import form outlook end -->
    <el-dialog 
      :visible.sync="bulkContactsDeleting" 
      title="Warning" 
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-draggable
    >
   
      <p style="font-family: inter, sans-serif">The following contacts are associated with their respective contact types. Deleting these contacts will result in the inability to send documents.</p>
      <el-row class="labelStyles">
        <el-col :span="10">Contacts</el-col>
        <el-col :span="14">Contact Types</el-col>
      </el-row>
    <br/>
    <div class="sidemenu">
      <div
        class="alertPopupStyle"
        v-for="(name, index) in namesAndContactTypes"
        :key="index"
      >
        <el-row>
          <el-col :span="10">{{ index + 1 + " " + "-" + " " + name.name }}</el-col>
          <el-col :span="1">--</el-col>
          <el-col :span="13">{{ name.contact_types && name.contact_types.length ? name.contact_types.join(",") : name.contact_type.name }}</el-col>
        </el-row>
        <br />
      </div>
    </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="selectedContactDelete"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>

<script>
import axios from "@/config/axios";
import { mapGetters } from "vuex";

import PermissionsHelper from "@/mixins/permissionsHelper";
import config from "./../../config/app";
import auth from "./../../helpers/microsoftAuth";
import graph from "./../../helpers/microsoftGraph";
import AccessManagement from "@/mixins/AccessManagement"

export default {
  name: "contacts-Contacts",
  props: ["pageSizes", "type", "categoryId", "groupId"],
  mixins: [PermissionsHelper,AccessManagement],
  data() {
    return {
      contacts: [],
      centerDialogVisible: false,
      selectedContact: [],

      client: null,
      userPrimaryDetailsForSS: [
        "first_name",
        "last_name",
        "email",
        "phone_number",
        "address",
        "company_name",
        "title",
      ],
      activeStep: 0,
      order_type: null,
      order_by: null,
      importFromSSActionModal: false,
      activeNameForImportFromSS: "success",

      totalForIC: 0,
      currentPageForIC: 1,
      pageSizeForIC: 5,
      totalForICError: 0,
      currentPageForICError: 1,
      pageSizeForICError: 5,

      filter_contact_type: "",
      // filtered_data: [],
      activeNameForImport: "success",
      state: "close",
      searchedContacts: [],
      searchedContact: " ",
      contactUsers: [],
      currentActiveRowIndex: -1,
      currentActiveSuccessRowIndex: -1,
      primaryHeaders: [],
      uploadedCSVDataResult: [],
      successfullCSVData: [],
      errorfullCSVData: [],
      namesAndContactTypes: [],
      bulkContactsDeleting: false,
      showImportContactsPopup: false,
      showImportContactsOutlookPopup: false,
      importTotalContactsCnt: 0,
      importAddedContactsCnt: 0,
      importUpdatedContactsCnt: 0,
      importFailedContactsCnt: 0,
      importErrorDetails: [],
      viewImportingContactsResultPopup: false,
      allContacts: [],
      contactdeletionloading: false,
      loading: false,
      loadingText: "Loading...",
      modalLoading: false,
      isVisible: true,
      documentActionModal: false,
      editDailogVisible: false,
      selectedCompanyDocument: "",
      total: 0,
      currentPage: 1,
      pageSize: 0,
      data: [],
      configureLoading: false,
      openSendDocumentsModal: false,
      documentUsers: [],
      showSendDocumentoUserSettings: false,
      enforceSignatureOrder: null,
      searchedContactForSend: " ",
      search_string: "",
      sort: " ",
      isContactsCreated: false,
      getBulkSendPercentage: 0,
      outlookUser: {},
      error: "",
      outLookAccessToken: "",
      // Details fetched from Graph API, user object and photo
      graphDetails: null,
      graphPhoto: null,
      // Visibility toggles for the three details modal popups
      showUserDetails: false,
      showGraphDetails: false,
      showTokenDetails: false,
      outlookUsersData: [],
    };
  },
  computed: {
    groupedContacts() {
    const grouped = {};
    for (const { name, contact_types } of this.namesAndContactTypes) {
      if (!grouped[name]) {
        grouped[name] = {
          contactName: name,
          contactTypes: new Set(),
        };
      }
      grouped[name].contactTypes = new Set([...grouped[name].contactTypes, ...contact_types]);
    }

    return Object.values(grouped);
  },
    getStandardContactType() {
      if (this.getAllContactTypes && this.getAllContactTypes.length) {
        let contact_type_check = this.getAllContactTypes.find((e) => {
          if (e.type == "STANDARD" && e.slug == "receiver") {
            return e;
          }
        });
        if (contact_type_check?._id) {
          return contact_type_check?._id;
        }
        return null;
      }
      return null;
    },
    getCurrentPageOfErrorfullCSVData() {
      const from = (this.currentPageForICError - 1) * this.pageSizeForICError;
      let to = from + this.pageSizeForICError;
      if (to > this.totalForICError) {
        to = this.totalForICError;
      }

      return this.errorfullCSVData.slice(from, to);
    },

    paginationPropsForICError() {
      return [5, 10, 20, 50];
    },
    getCurrentPageOfSuccessfullCSVData() {
      const from = (this.currentPageForIC - 1) * this.pageSizeForIC;
      let to = from + this.pageSizeForIC;
      if (to > this.totalForIC) {
        to = this.totalForIC;
      }
      return this.successfullCSVData.slice(from, to);
    },
    paginationPropsForIC() {
      return [5, 10, 20, 50];
    },
    ...mapGetters("contacts", [
      "getAllContacts",
      "getContactDeleteStatus",
      "getContact",
    ]),
    ...mapGetters("contactTypes", ["getAllContactTypes"]),
    ...mapGetters("navigationOpen", ["getIsOpen", "getIsMobile"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
    ]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [5, 10, 20, 50],
      };
    },
    isImage() {
      return (type) => (type && type.indexOf("image") > -1 ? true : false);
    },
    isPdf() {
      return (type) => (type && type.indexOf("pdf") > -1 ? true : false);
    },
    fetchContactEmails() {
      // return (this.successfullCSVData || []).flatMap(e => e.email);
      return (email) =>
        email &&
        this.successfullCSVData.filter((e) => e.email == email).length > 1
          ? false
          : true;
    },
    fetchErrorContactEmails() {
      // return (this.successfullCSVData || []).flatMap(e => e.email);
      return (email) =>
        email &&
        this.successfullCSVData.filter((e) => e.email == email).length == 0
          ? false
          : true;
    },
  },
  async mounted() {
    await this.$store.dispatch("contactTypes/fetchAllContactTypes", {
      get_all: true,
      from_contacts: true,
          type:'CONTACT_TYPE'
    });
    await this.fetchContacts();
    try {
      var self = this;
      console.log(
        ">>> mounted #1",
        document.getElementsByClassName("doc-modal")
      );
      var elParentArr = document.getElementsByClassName("doc-modal");
      for (var i = 0; i < elParentArr.length; i++) {
        // console.log(">>> mounted #2, elParent", elParentArr[i]);
        var el = elParentArr[i].getElementsByClassName(
          "el-dialog__headerbtn"
        )[0];
        el.addEventListener("click", () => {
          document.querySelector("body").classList.remove("modal-open");
          self.resetBulkModal();
        });
      }
    } catch {
      // console.log('>>> mounted(), err');
    }
  },
  async created() {
    // Basic setup of MSAL helper with client id, or give up
    if (config.AZURE_APP_ID && config.AZURE_APP_SECRET) {
      auth.configure(config.AZURE_APP_ID, config.AZURE_APP_SECRET, false);
      // Restore any cached or saved local user
      // this.outlookUser =await  auth.user();
      console.log(`configured ${auth.isConfigured()}`);
    } else {
      this.error =
        "outlook_CLIENT_ID is not set, the app will not function! 😥";
    }
  },
  methods: {
    getContactName(id) {
      if (this.getAllContactTypes && id) {
        let contact_type = this.getAllContactTypes.find((e) => e._id == id);
        if (contact_type?.name) {
          return contact_type.name;
        }
      }
      return "";
    },
    closePopup() {
      this.importFromSSActionModal = false;
    },
    updateInput() {
      // this.$emit("search_string", this.search_string);
    },
    handleSelectionChange(val) {
      this.selectedContact = [];
      this.selectedContact = val;
    },
    async selectedContactDelete() {
      this.bulkContactsDeleting = false;
      this.centerDialogVisible = false;
      this.selectedContact.forEach(async (e, index) => {
            this.loading = true;
             await this.$store.dispatch("contacts/deleteContact", e._id);
            if (index + 1 == this.selectedContact.length) {
              await this.fetchContacts();
              this.$notify.success({
                title: "Success",
                message: "Contacts deleted successfully",
              });
              this.loading = false;
            }
      });
    },
    getContactTypeIdFromValue(value) {
      let Id = "";
      try {
        if (this.getAllContactTypes && this.getAllContactTypes.data) {
          this.getAllContactTypes.data.forEach((type) => {
            if (type.name == value) {
              Id = type._id;
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
      if (Id == "") {
        try {
          if (this.getAllContactTypes) {
            this.getAllContactTypes.forEach((type) => {
              if (type.name == value) {
                Id = type._id;
              }
            });
          }
        } catch (err) {
          console.log(err);
        }
      }
      return Id;
    },
    updatedDateSort: function (a, b) {
      if (a.updated_at < b.updated_at) return -1;
      if (a.updated_at > b.updated_at) return 1;
      return 0;
    },
    // checkNameValidation(val) {
    //   if (val && val.match(/^\d/)) {
    //     return false;
    //   }
    //   return true;
    // },
    checkNameValidation(val) {
  const regex = /^[a-zA-Z0-9]+$/;
  return regex.test(val);
},
    async separateSuccessAndErrors() {
      this.uploadedCSVDataResult.forEach((row, index) => {
        let flag = true;
        let existEmail = false;
        var keys = Object.keys(row);
        keys.forEach((key) => {
          if (key == "first_name" || key == "last_name") {
            if (!row[key] || (row[key] && row[key].length < 1)) {
              flag = false;
            }
            if (!this.checkNameValidation(row[key])) {
              flag = false;
            }
          } else if (key == "email") {
            for (
              let i = index + 1;
              i < this.uploadedCSVDataResult.length;
              i++
            ) {
              if (
                this.uploadedCSVDataResult[i] &&
                row[key] &&
                this.uploadedCSVDataResult[i]["email"] == row[key]
              ) {
                existEmail = true;
              }
            }
            if (
              existEmail ||
              !row[key] ||
              (row[key] &&
                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
                  row[key]
                ))
            ) {
              flag = false;
            }
          } else if (
            key == "phone_number" &&
            row[key] &&
            !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(
              row[key]
            )
          ) {
            flag = false;
            // console.log(">>> checkErrorCountOnSuccessTab(), phone #2");
          }
        });
        if (flag) {
          this.successfullCSVData.push(row);
        } else if (!existEmail) {
          this.errorfullCSVData.push(row);
        }
      });

      if (this.errorfullCSVData && this.errorfullCSVData.length) {
        this.rearrangeTheErrorCSVData();
      }
      this.successfullCSVData.map((row, index) => {
        row["id"] = index;
      });
      this.totalForIC = this.successfullCSVData.length;
      this.totalForICError = this.errorfullCSVData.length;
    },
    async divideString(e) {
      let removeNewLines = e.replace(/"([^"]*)"/g, (match, group) => {
        return `"${group.replace(/\n/g, " ")}"`;
      });
      let removeDoubleQuotes = removeNewLines.replace(/"([^"]*)"/g, "$1");
      console.log("1111111111", removeDoubleQuotes);
      let dividedString = removeDoubleQuotes.split(/\r?\n/);
      if (dividedString && dividedString.length <= 1) {
        this.$message.error("Oops, Uploaded CSV file is empty.");
      } else {
        const headersTmp = dividedString[0].split(",");
        // console.log(">>> divideString(), headersTmp", headersTmp);

        let headers = [];
        let headersPos = [];
        headersTmp.forEach((item, index) => {
          let itemTmp = item.trim().toLowerCase();
          if (
            itemTmp == "name" ||
            itemTmp == "full name" ||
            itemTmp == "fullname"
          ) {
            headers.push("name");
            headersPos.push(index);
          } else if (
            itemTmp == "first_name" ||
            itemTmp == "first name" ||
            itemTmp == "firstname"
          ) {
            headers.push("first_name");
            headersPos.push(index);
          } else if (
            itemTmp == "last_name" ||
            itemTmp == "last name" ||
            itemTmp == "lastname"
          ) {
            headers.push("last_name");
            headersPos.push(index);
          } else if (
            itemTmp == "email" ||
            itemTmp == "email address" ||
            itemTmp == "email_address" ||
            itemTmp == "emailaddress"
          ) {
            headers.push("email");
            headersPos.push(index);
          } else if (
            itemTmp == "phone_number" ||
            itemTmp == "phone number" ||
            itemTmp == "phonenumber" ||
            itemTmp == "phone" ||
            itemTmp == "cell"
          ) {
            headers.push("phone_number");
            headersPos.push(index);
          } else if (
            itemTmp == "address" ||
            itemTmp == "home address" ||
            itemTmp == "home_address" ||
            itemTmp == "office address" ||
            itemTmp == "office_address"
          ) {
            headers.push("address");
            headersPos.push(index);
          } else if (
            itemTmp == "company_name" ||
            itemTmp == "company name" ||
            itemTmp == "company_name" ||
            itemTmp == "company"
          ) {
            headers.push("company_name");
            headersPos.push(index);
          } else if (itemTmp == "title" || itemTmp == "position") {
            headers.push("title");
            headersPos.push(index);
          }
        });
        let differentFields = this.userPrimaryDetailsForSS.filter(
          (x) => headers.indexOf(x) === -1
        );
        if (
          differentFields &&
          differentFields.length &&
          differentFields.length >= this.userPrimaryDetailsForSS.length
        ) {
          this.$message.error(
            "Oops, Uploaded CSV file has no matched columns with required CSV."
          );
        } else {
          this.isCSVUploaded = true;
          this.uploadedCSVDataResult = [];
          for (let i = 1; i < dividedString.length; i++) {
            let obj = {};
            let str = dividedString[i];
            if (dividedString[i] && dividedString[i].length) {
              let properties = str.split(",");
              this.userPrimaryDetailsForSS.forEach((h) => {
                obj[h] = "";
              });
              for (let k in headers) {
                if (properties[headersPos[k]] != "") {
                  const j = headersPos[k];
                  if (properties[j] && properties[j].includes(",")) {
                    obj[headers[k]] = properties[j]
                      .split(",")
                      .map((item) => item.trim());
                  } else {
                    obj[headers[k]] = properties[j];
                  }
                }
              }

              var isEmpty = true;
              this.userPrimaryDetailsForSS.forEach((h) => {
                if (obj[h] && obj[h].trim().length > 0) {
                  isEmpty = false;
                }
              });
              if (!isEmpty) {
                if (
                  this.filter_contact_type &&
                  this.filter_contact_type.length
                ) {
                  let id = this.getContactTypeIdFromValue(
                    this.filter_contact_type
                  );
                  if (id) {
                    obj.contact_type = id;
                  } else {
                    obj.contact_type = this.getStandardContactType;
                  }
                } else {
                  obj.contact_type = this.getStandardContactType;
                }
                // if (
                //   this.filter_contact_type &&
                //   this.filter_contact_type.length
                // ) {
                //   obj.contact_type = this.filter_contact_type;
                // } else {

                // else {
                //   obj.contact_type = "RECEIVER";
                //   await this.$store.dispatch(
                //     "contactTypes/createContactType",
                //     {
                //       name: obj.contact_type,
                //     }
                //   );
                // }
                // }
                if ("name" in obj) {
                  var tmpName = obj.name;
                  var words = tmpName.split(" ");
                  if (words.length === 1) {
                    obj.first_name = words[0];
                  } else if (words.length > 1) {
                    obj.last_name = words[words.length - 1];
                    const pos = tmpName.lastIndexOf(obj.last_name);
                    if (pos >= 0) {
                      obj.first_name = tmpName.substring(0, pos);
                    }
                  }
                  delete obj.name;
                }
                this.uploadedCSVDataResult.push(obj);
              }
            }
          }
        }

        this.primaryHeaders.push(
          "contact_type",
          "first_name",
          "last_name",
          "email",
          "phone_number",
          "address",
          "company_name",
          "title"
        );

        this.separateSuccessAndErrors();
      }
      // let result = [];
    },
    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.fetchContacts();
      // console.log("hello how are you", a);
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.fetchContacts();
      }
      // console.log(a);
    },
    uploadBulkSendCSV(file) {
      // console.log(">>> uploadBulkSendCSV() called");
      if (
        file.raw.type == "application/vnd.ms-excel" ||
        file.raw.type == "text/csv"
      ) {
        this.uploadedCSVFile = file.raw.name;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.resetCSVData();
          this.activeStep = 1;
          this.divideString(e.target.result);
        };
        reader.readAsText(file.raw);
      } else {
        this.$message.error("Oops, Please upload a .CSV file.");
      }
    },
    generateCSVFile() {
      let primaryHeads = [];
      primaryHeads.push(
        "first_name",
        "last_name",
        "middle_name",
        "email",
        "phone_number",
        "address",
        "company_name",
        "title"
      );

      if (!this.isGenerateCSVFile) {
        this.downloadCSVFile(primaryHeads.join(","));
      }
      this.isGenerateCSVFile = false;
    },
    downloadCSVFile(data) {
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", `importFromSpreadsheet.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    handleSizeChangeForIC(val) {
      // console.log(`${val} items per page`);
      this.pageSizeForIC = val;
    },
    handleCurrentChangeForIC(val) {
      this.currentPageForIC = val;
    },
    handleScroll() {
      // console.log(">>> handleScroll() called");
      let scrollbarEl = this.$refs.import_contacts_el_scroll_body;
      // console.log(">>> handleScroll() called, scrollbarEl", scrollbarEl);
      scrollbarEl.onscroll = () => {
        if (scrollbarEl.scrollTop > 135) {
          this.isFixedTop = true;
        } else {
          this.isFixedTop = false;
        }
      };
    },
    async onChangeFilterContactType() {
      await this.fetchContacts();
      // if (this.filter_contact_type == "") {
      //   this.data = this.getAllContacts.data;
      //   // this.total = this.filtered_data.length;
      // }
      // if (this.search_string == "") {
      //   this.data = this.getAllContacts.data;
      // } else {
      //   this.data = this.getAllContacts.data.filter(
      //     (element) =>
      //       element.contact_type.name == this.filter_contact_type ||
      //       element.contact_type.name == this.search_string
      //   );
      //   // this.total = this.filtered_data.length;
      // }
      // this.fetchContacts();
    },
    async onSearchContactType() {
      await this.fetchContacts();
      if (this.search_string == "") {
        this.data = this.getAllContacts.data;
        // this.total = this.filtered_data.length;
      } else {
        this.data = this.getAllContacts.data.filter(
          (element) => element.contact_type.name == this.search_string
        );
        // this.total = this.filtered_data.length;
      }
    },
    bulkDelete() {
      
      this.namesAndContactTypes = [];
      if (!this.checkPermissionByPermissionName("deleteContact")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for delete contacts.Please contact owner.",
        });
      } else {
        this.selectedContact.forEach(async (e) => {
        if (
          (e.first_name &&
          e.last_name) ||
          e.contact_type ||
          (e.contact_types &&
          e.contact_types.length)
        ) {
          console.log("eeeeeeeeeeeeeeee",e.contact_type)
          this.bulkContactsDeleting = true;
          let contactTypes = e.contact_types
            .filter((el) => el?.contact_type?.name)
            .flatMap((el) => el?.contact_type?.name);
            let obj = {
              name  : e.first_name+ ' ' + e.last_name,
              contact_types : contactTypes,
              contact_type : e.contact_type
            }
          this.namesAndContactTypes.push(obj);
        }
      })
      }
    },
    async addContact(aContact) {
      const contactFormData = {};
      contactFormData.first_name = aContact.first_name;
      contactFormData.last_name = aContact.last_name;
      contactFormData.email = aContact.email;
      contactFormData.phone_number = aContact.phone_number;
      contactFormData.address = aContact.address;
      contactFormData.first_name = aContact.first_name;
      contactFormData.company_name = aContact.company_name;
      contactFormData.title = aContact.title;
      contactFormData.contact_type = aContact.contact_type;
      if (this.getActiveWorkspace && this.getActiveWorkspace.company_id) {
        contactFormData.contact_id = this.getActiveWorkspace.company_id;
      }

      let resultStr = "";
      let userName = "";

      try {
        const _id = this.getContactIdFromEmail(contactFormData.email);
        // console.log("------------============--------------", _id);
        if (_id && _id === "") {
          // console.log("id exists");
          // not exist, so add
          await axios.post("/contacts", contactFormData);
        } else {
          // console.log("id not exists");
          // existing contact, so update
          try {
            await axios.patch(`/contacts/${_id}`, contactFormData);
            this.importUpdatedContactsCnt++;
            // eslint-disable-next-line no-empty
          } catch (err02) {}
        }
      } catch (err) {
        if (
          err.response.data.success === false &&
          err.response.data.status_code === 409
        ) {
          // Contact with this email is already taken
          const _id = this.getContactIdFromEmail(contactFormData.email);
          // console.log('>>> Allcontacts', this.allContacts);
          // console.log('>>> _id, email', _id, contactFormData.email, contactFormData);
          try {
            await axios.patch(`/contacts/${_id}`, contactFormData);
            this.importUpdatedContactsCnt++;
            // eslint-disable-next-line no-empty
          } catch (err02) {}
        } else {
          // failed
          resultStr =
            (userName === "" ? "" : userName + ": ") +
            "failed to add, unknown reason";
        }
      }
      return resultStr;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async importContacts() {
      this.showImportContactsPopup = false;
      this.showImportContactsOutlookPopup = false;
      this.importFromSSActionModal = false;
      this.loadingText = "Importing Contacts...";
      this.loading = true;
      // console.log(">>> importContacts #1");
      this.importTotalContactsCnt = this.successfullCSVData.length;
      this.importAddedContactsCnt = 0;
      this.importUpdatedContactsCnt = 0;
      this.importFailedContactsCnt = 0;
      this.importErrorDetails = [];

      const batchCnt = 50;
      var cntDone = 0;
      this.importAddedContactsCnt = 0;
      this.importAddedContactsCnt = 0;
      for (
        let k = 0;
        k < Math.floor(this.successfullCSVData.length / batchCnt) + 1;
        k++
      ) {
        let contactsData = [];
        for (let i = 0; i < batchCnt; i++) {
          if (k * batchCnt + i >= this.successfullCSVData.length) {
            break;
          }
          var aContact = this.successfullCSVData[k * batchCnt + i];
          const contactFormData = {};
          contactFormData.first_name = aContact.first_name;
          contactFormData.last_name = aContact.last_name;
          contactFormData.middle_name = aContact.middle_name;
          contactFormData.email = aContact.email;
          contactFormData.phone_number = aContact.phone_number;
          contactFormData.address = aContact.address;
          contactFormData.first_name = aContact.first_name;
          contactFormData.company_name = aContact.company_name;
          contactFormData.title = aContact.title;
          contactFormData.contact_type = aContact.contact_type;
          contactFormData.created_by = this.getAuthenticatedUser._id;
          if (this.getActiveWorkspace && this.getActiveWorkspace.company_id) {
            contactFormData.contact_id = this.getActiveWorkspace.company_id;
          }

          contactsData.push(contactFormData);
        }
        var resp = await axios.post("/contacts-bulk", contactsData);
        // console.log('>>> importContacts(), resp', resp.data);
        // this.importAddedContactsCnt += resp.data.cntAdded;
        // this.importUpdatedContactsCnt += resp.data.cntUpdated;
        console.log("ew", resp.data);
        this.importSuccessMessage = resp.data.message;
        cntDone += contactsData.length;
        this.loadingText =
          "Importing Contacts " +
          "(" +
          cntDone +
          "/" +
          this.successfullCSVData.length +
          ")" +
          " ...";
      }
      // self.fetchContacts();
      this.loading = false;
      // this.importFailedContactsCnt =
      //   this.importTotalContactsCnt -
      //   this.importAddedContactsCnt -
      //   this.importUpdatedContactsCnt;
      this.importFromSSActionModal = true;
      this.viewImportingContactsResultPopup = true;
      this.resetCSVData();
      document.body.className += " modal-open";
      // this.pageSize = 5;
      this.fetchContacts();
      this.$forceUpdate();
    },

    async importContacts_Old() {
      this.showImportContactsPopup = false;
      this.showImportContactsOutlookPopup = false;
      this.importFromSSActionModal = false;
      this.loadingText = "Importing Contacts...";
      this.loading = true;
      // console.log(">>> importContacts #1");

      // await this.fetchAllContacts();
      const totalOld = this.total;

      this.importTotalContactsCnt = this.successfullCSVData.length;
      this.importAddedContactsCnt = 0;
      this.importUpdatedContactsCnt = 0;
      this.importFailedContactsCnt = 0;
      this.importErrorDetails = [];

      // console.log(">>> importContacts #2");
      var cntDone = 0;
      var cntToDo = this.successfullCSVData.length;
      var self = this;
      this.loading = true;
      self.loadingText =
        "Importing Contacts " + "(" + cntDone + "/" + cntToDo + ")" + " ...";
      // console.log(">>> addContact(), self.loadingText", self.loadingText);
      const batchCnt = 100;
      for (let k = 0; k < this.successfullCSVData.length / batchCnt + 1; k++) {
        // for (let i = 0; i < this.successfullCSVData.length; i++) {
        while (cntDone < k * batchCnt) {
          await this.sleep(50);
        }
        for (let i = 0; i < batchCnt; i++) {
          var person = this.successfullCSVData[k * batchCnt + i];

          try {
            this.addContact(person)
              .then((resultStr) => {
                if (resultStr !== "") {
                  self.importErrorDetails.push(resultStr);
                }
                cntDone++;
                self.loadingText =
                  "Importing Contacts " +
                  "(" +
                  cntDone +
                  "/" +
                  cntToDo +
                  ")" +
                  " ...";

                if (cntDone >= cntToDo) {
                  self.loading = false;

                  // await self.fetchContacts();
                  self.fetchContacts();

                  self.importAddedContactsCnt = self.total - totalOld;
                  self.importFailedContactsCnt =
                    self.importTotalContactsCnt -
                    self.importAddedContactsCnt -
                    self.importUpdatedContactsCnt;
                  self.importFromSSActionModal = true;
                  self.viewImportingContactsResultPopup = true;
                }
              })
              .catch(() => {
                // self.loadingText = "Importing Contacts " + "(" + cntDone + "/" + cntToDo + ")" + " ...";
                // console.log('>>> addContact(), > catch, self.loadingText', self.loadingText);
                // if (cntDone >= cntToDo) {
                //   self.loading = false;
                //   // await self.fetchContacts();
                //   self.fetchContacts();
                //   self.importAddedContactsCnt = self.total - totalOld;
                //   self.importFailedContactsCnt =
                //     self.importTotalContactsCnt -
                //     self.importAddedContactsCnt -
                //     self.importUpdatedContactsCnt;
                //   self.viewImportingContactsResultPopup = true;
                // }
              });
          } catch (err) {
            // self.loadingText = "Importing Contacts " + "(" + cntDone + "/" + cntToDo + ")" + " ...";
            // console.log('>>> addContact(), > try catch, self.loadingText', self.loadingText);
            // if (cntDone >= cntToDo) {
            //   self.loading = false;
            //   // await self.fetchContacts();
            //   self.fetchContacts();
            //   self.importAddedContactsCnt = self.total - totalOld;
            //   self.importFailedContactsCnt =
            //     self.importTotalContactsCnt -
            //     self.importAddedContactsCnt -
            //     self.importUpdatedContactsCnt;
            //   self.viewImportingContactsResultPopup = true;
            // }
          }
        }
      }
    },
    editSuccessCSVData(row) {
      this.currentActiveSuccessRowIndex = row.id;
    },
    resetSuccessCSVData(row, column) {
      if (column && column.label == "contact_type") {
        return;
      }
      let checkError = this.checkErrorCountOnSuccessTab();
      if (!checkError) {
        this.successfullCSVData.splice(this.currentActiveSuccessRowIndex, 1);
        this.rearrangeTheErrorCSVData();
      }
      this.currentActiveSuccessRowIndex = -1;
      // this.errorfullCSVData.splice(this.currentActiveRowIndex, 1);

      this.totalForIC = this.successfullCSVData.length;
      this.totalForICError = this.errorfullCSVData.length;
    },
    editErrorCSVData(row) {
      this.currentActiveRowIndex = row.id;
    },
    /*async fetchContacts(){
      try{
        this.configureLoading = true
      }catch(err){
        this.configureLoading = false
      }
    },*/
    resetErrorCSVData(row, column) {
      if (column && column.label == "contact_type") {
        return;
      }
      let checkError = this.checkErrorCount();
      if (checkError) {
        this.errorfullCSVData.splice(this.currentActiveRowIndex, 1);
        this.rearrangeTheErrorCSVData();
      }
      this.currentActiveRowIndex = -1;
      // this.errorfullCSVData.splice(this.currentActiveRowIndex, 1);

      this.totalForIC = this.successfullCSVData.length;
      this.totalForICError = this.errorfullCSVData.length;
    },
    rearrangeTheErrorCSVData() {
      this.errorfullCSVData.map((row, index) => {
        row["id"] = index;
      });
      this.successfullCSVData.map((row, index) => {
        row["id"] = index;
      });
    },
    checkErrorCountOnSuccessTab() {
      var keys = Object.keys(
        this.successfullCSVData[this.currentActiveSuccessRowIndex]
      );
      let flag = true;
      keys.forEach((key) => {
        // console.log('>>> checkErrorCount(), key, this.successfullCSVData[this.currentActiveSuccessRowIndex][key]', key, this.successfullCSVData[this.currentActiveSuccessRowIndex][key]);
        if (key == "first_name" || key == "last_name") {
          if (
            !this.successfullCSVData[this.currentActiveSuccessRowIndex][key] ||
            (this.successfullCSVData[this.currentActiveSuccessRowIndex][key] &&
              this.successfullCSVData[this.currentActiveSuccessRowIndex][key]
                .length < 1)
          ) {
            flag = false;
          }
          if (
            !this.checkNameValidation(
              this.successfullCSVData[this.currentActiveSuccessRowIndex][key]
            )
          ) {
            flag = false;
          }
        } else if (key == "email") {
          if (
            !this.successfullCSVData[this.currentActiveSuccessRowIndex][key] ||
            (this.successfullCSVData[this.currentActiveSuccessRowIndex][key] &&
              (!this.fetchContactEmails(
                this.successfullCSVData[this.currentActiveSuccessRowIndex][key]
              ) ||
                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
                  this.successfullCSVData[this.currentActiveSuccessRowIndex][
                    key
                  ]
                )))
          ) {
            flag = false;
          }
          if (this.successfullCSVData[this.currentActiveSuccessRowIndex][key]) {
            // console.log(
            //   ">>>",
            //   flag,
            //   this.fetchContactEmails(
            //     this.successfullCSVData[this.currentActiveSuccessRowIndex][key]
            //   )
            // );
          }
        } else if (key == "phone_number") {
          // console.log('>>> checkErrorCountOnSuccessTab(), this.successfullCSVData', this.successfullCSVData);

          if (
            // !this.successfullCSVData[this.currentActiveSuccessRowIndex][key] ||
            this.successfullCSVData[this.currentActiveSuccessRowIndex][key] &&
            !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(
              this.successfullCSVData[this.currentActiveSuccessRowIndex][key]
            )
          ) {
            flag = false;
            // console.log(">>> checkErrorCountOnSuccessTab(), phone #2");
          }
        }
        // } else if (
        //   key == "address" ||
        //   key == "company"
        // ) {
        //   if (!this.successfullCSVData[this.currentActiveSuccessRowIndex][key]) {
        //     flag = false;
        //   }
        // }
      });
      if (!flag) {
        this.errorfullCSVData.push(
          this.successfullCSVData[this.currentActiveSuccessRowIndex]
        );
      }
      // console.log(
      //   ">>> checkErrorCount(), flag, this.errorfullCSVData[this.currentActiveSuccessRowIndex]",
      //   flag,
      //   this.successfullCSVData[this.currentActiveSuccessRowIndex]
      // );

      return flag;
    },
    checkErrorCount() {
      var keys = Object.keys(this.errorfullCSVData[this.currentActiveRowIndex]);
      let flag = true;
      keys.forEach((key) => {
        // console.log('>>> checkErrorCount(), key, this.errorfullCSVData[this.currentActiveRowIndex][key]', key, this.errorfullCSVData[this.currentActiveRowIndex][key]);
        if (key == "first_name" || key == "last_name") {
          if (
            !this.errorfullCSVData[this.currentActiveRowIndex][key] ||
            (this.errorfullCSVData[this.currentActiveRowIndex][key] &&
              this.errorfullCSVData[this.currentActiveRowIndex][key].length < 1)
          ) {
            flag = false;
          }
          if (
            !this.checkNameValidation(
              this.errorfullCSVData[this.currentActiveRowIndex][key]
            )
          ) {
            flag = false;
          }
        } else if (key == "email") {
          if (
            !this.errorfullCSVData[this.currentActiveRowIndex][key] ||
            (this.errorfullCSVData[this.currentActiveRowIndex][key] &&
              (this.fetchErrorContactEmails(
                this.errorfullCSVData[this.currentActiveRowIndex][key]
              ) ||
                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
                  this.errorfullCSVData[this.currentActiveRowIndex][key]
                )))
          ) {
            flag = false;
          }
          if (this.errorfullCSVData[this.currentActiveRowIndex][key]) {
            // console.log(
            //   ">>>",
            //   flag,
            //   this.fetchErrorContactEmails(
            //     this.errorfullCSVData[this.currentActiveRowIndex][key]
            //   )
            // );
          }
          // console.log('>>> checkErrorCount(), ### flag, this.errorfullCSVData[this.currentActiveRowIndex][key]', flag, this.errorfullCSVData[this.currentActiveRowIndex][key]);
        } else if (key == "phone_number") {
          if (
            // !this.errorfullCSVData[this.currentActiveRowIndex][key] ||
            this.errorfullCSVData[this.currentActiveRowIndex][key] &&
            !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(
              this.errorfullCSVData[this.currentActiveRowIndex][key]
            )
          ) {
            flag = false;
          }
        }
        // } else if (
        //   key == "address" ||
        //   key == "company"
        // ) {
        //   if (!this.errorfullCSVData[this.currentActiveRowIndex][key]) {
        //     flag = false;
        //   }
        // }
      });
      if (flag) {
        this.successfullCSVData.push(
          this.errorfullCSVData[this.currentActiveRowIndex]
        );
      }
      // console.log(
      //   ">>> checkErrorCount(), flag, this.errorfullCSVData[this.currentActiveRowIndex]",
      //   flag,
      //   this.errorfullCSVData[this.currentActiveRowIndex]
      // );

      return flag;
    },
    reuploadCSV() {
      // console.log(">>> reuploadCSV() called");
      if (!this.isBulkSendIsProccessing) {
        this.resetCSVData();
        this.selectedCofigurable = null;
        this.selectedCompanyDocument = null;
        this.activeHeadingNames = [];
        this.getBulkSendPercentage = 0;
        this.isContactsCreated = false;
        this.contactLoading = false;
        // this.importFromSSActionModal = false;
        this.isGenerateCSVFile = false;
        this.primaryHeaders = [];
        // this.currentActiveSuccessRowIndex = -1;
        // this.currentActiveRowIndex = -1;
        this.uploadedCSVFile = "";
        this.docSettingsVisible = false;
      }
    },
    handleClosePopup() {
      this.resetCSVData();
      // this.refreshDocument();
    },

    resetBulkModal() {
      // console.log(">>> resetBulkModal() called");
      if (!this.isBulkSendIsProccessing) {
        this.resetCSVData();
        this.selectedCofigurable = null;
        this.selectedCompanyDocument = null;
        this.activeHeadingNames = [];
        this.getBulkSendPercentage = 0;
        this.isContactsCreated = false;
        this.contactLoading = false;
        this.importFromSSActionModal = false;
        this.showImportContactsPopup = false;
        this.showImportContactsOutlookPopup = false;
        this.isGenerateCSVFile = false;
        this.primaryHeaders = [];
        this.currentActiveSuccessRowIndex = -1;
        this.currentActiveRowIndex = -1;
        this.uploadedCSVFile = "";
        this.docSettingsVisible = false;
      }
    },
    resetCSVData() {
      this.successfullCSVData = [];
      this.errorfullCSVData = [];
      this.uploadedCSVDataResult = [];
      this.totalForIC = 0;
      this.totalForICError = 0;
      this.activeStep = 0;
    },
    format(percentage) {
      let per = percentage === 100 ? "" : `` || percentage != 100 ? "" : ``;
      console.log(per);
      let doc = "";
      return doc;
    },

    async separateSuccessAndErrorsFromGmail(connections) {
      // console.log(
      //   ">>> separateSuccessAndErrorsFromGmail() called, connections",
      //   connections
      // );
      this.primaryHeaders = [];
      this.primaryHeaders.push("contact_type");
      this.primaryHeaders.push("first_name");
      this.primaryHeaders.push("last_name");
      this.primaryHeaders.push("middle_name");
      this.primaryHeaders.push("email");
      this.primaryHeaders.push("phone_number");
      this.primaryHeaders.push("address");
      this.primaryHeaders.push("company_name");
      this.primaryHeaders.push("title");

      this.uploadedCSVDataResult = [];
      this.successfullCSVData = [];
      this.errorfullCSVData = [];

      for (let i = 0; i < connections.length; i++) {
        var aContact = connections[i];
        var aContactForRow = {};

        try {
          if (this.filter_contact_type == "") {
            // aContactForRow.contact_type = "Receiver";
            aContactForRow.contact_type = this.getStandardContactType;
          } else {
            aContactForRow.contact_type = this.filter_contact_type;
          }

          if (aContact.names && aContact.names.length > 0) {
            // first_name
            if (aContact.names[0].givenName) {
              aContactForRow.first_name = aContact.names[0].givenName;
            } else {
              aContactForRow.first_name = aContact.names[0].displayName;
            }

            // last_name
            if (aContact.names[0].familyName) {
              aContactForRow.last_name = aContact.names[0].familyName;
            } else {
              aContactForRow.last_name = "";
            }
          }

          // email
          if (aContact.emailAddresses && aContact.emailAddresses.length > 0) {
            if (aContact.emailAddresses[0].value) {
              aContactForRow.email = aContact.emailAddresses[0].value;
            }
          } else {
            aContactForRow.email = "";
          }

          // phone_number
          if (aContact.phoneNumbers && aContact.phoneNumbers.length > 0) {
            if (aContact.phoneNumbers[0].value) {
              aContactForRow.phone_number = aContact.phoneNumbers[0].value;
              // contactFormData.phone_number = '';
            }
          } else {
            aContactForRow.phone_number = "";
          }

          // address
          if (aContact.addresses && aContact.addresses.length > 0) {
            try {
              aContactForRow.address = this.buildCommaSeparatedString([
                aContact.addresses[0].streetAddress,
                aContact.addresses[0].city,
                aContact.addresses[0].region,
                aContact.addresses[0].country,
                aContact.addresses[0].postalCode,
              ]);
            } catch (e) {
              aContactForRow.address = "";
            }
          } else {
            aContactForRow.address = "";
          }

          // company_name
          aContactForRow.company_name = "";
          aContactForRow.title = "";
          if (aContact.organizations && aContact.organizations.length > 0) {
            if (aContact.organizations[0].name) {
              aContactForRow.company_name = aContact.organizations[0].name;
            }
            if (aContact.organizations[0].title) {
              aContactForRow.title = aContact.organizations[0].title;
            }
          }
          this.uploadedCSVDataResult.push(aContactForRow);
        } catch (err) {
          console.log(
            ">>> separateSuccessAndErrorsFromGmail() parsing error.",
            err
          );
        }
      }
      this.separateSuccessAndErrors();
    },

    async fetchAllContacts() {
      try {
        // this.loading = true;
        let params = { get_all: true };
        // fetchAllCompanyContacts
        let response = await axios.get("/contacts", { params });
        this.allContacts = response.data.data;
        // this.loading = false;
      } catch (err) {
        // this.loading = false;
      }
    },
    async fetchContacts() {
      try {
        this.loading = true;
        let params = {
          limit: this.pageSize > 0 ? this.pageSize : 5,
          page: this.currentPage,
        };
        let contact_type = "";
        try {
          contact_type = this.getContactTypeIdFromValue(
            this.filter_contact_type
          );
        } catch (err) {
          console.log(err);
        }
        if (contact_type != "") {
          params.contact_type = contact_type;
        }
        try {
          contact_type = this.getContactTypeIdFromValue(this.search_string);
        } catch (err) {
          console.log(err);
        }
        if (this.search_string) {
          params.search_string = this.search_string;
        }
        if (this.order_by) {
          params.order_by = this.order_by;
        }
        if (this.order_type) {
          params.order_type = this.order_type;
        }
        await this.$store.dispatch("contacts/fetchAllContacts", params);
        this.data = this.getAllContacts.data;
        this.total = this.getAllContacts.total;
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    onEdit(contact) {
      this.$router.push({
        name: "edit-contact",
        params: {
          contact_id: contact._id,
        },
      });
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.currentPage = data.page;
        await this.fetchContacts();
      }
    },

    async onDelete(contact) {
      let id = contact._id;
      let message = " Are you sure to delete the contact?";
      if (
        contact.first_name &&
        contact.last_name &&
        contact.contact_types &&
        contact.contact_types.length
      ) {
        let contactTypes = contact.contact_types
          .filter((e) => e?.contact_type?.name)
          .flatMap((e) => e?.contact_type?.name);
        message =
          `${contact.first_name} ${
            contact.last_name
          } contact is associated with ${contactTypes.join(
            ","
          )} . Deleting contact, you will not be able to send documents.` +
          message;
      }
      this.$confirm(message)
        .then(async () => {
          this.contactdeletionloading = true;
          await this.$store.dispatch("contacts/deleteContact", id);
          if (this.getContactDeleteStatus) {
            this.documentActionModal = false;
            await this.fetchContacts();
            this.$notify.success({
              title: "Success",
              message: "Contact deleted successfully",
            });
          } else {
            this.$notify.error({
              title: "Error",
              message: "Error in deleting contact",
            });
          }

          this.conatactdeletionloading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log("onDelete", err);
        });
    },
    goToCreateContact() {
      this.$router.push({ name: "add-contact" });
    },
    goToCreateContactType() {
      this.$router.push({ name: "contactTypes" });
    },
    goToContactList() {
      this.$router.push({
        path: "/contacts",
      });
    },
    goToDocuments(contact) {
      this.$router.push({
        name: "user-contacts",
        params: {
          contact_id: contact.email,
          name: contact.first_name,
          lastname: contact.last_name,
        },
      });
    },
    async goToImportGmailContact() {
      try {
        await this.$google.api.auth2.getAuthInstance().signIn();
        this.listConnectionNames();
      } catch (e) {
        console.log(e);
      }
    },
    async goToImportSpreadsheetContact() {
      this.resetCSVData();
      this.viewImportingContactsResultPopup = false;
      this.importFromSSActionModal = true;

      document.body.className += " modal-open";
    },
    async goToImportOutlookContact() {
      try {
        await auth.login();
        this.outlookUser = await auth.user();
      } catch (err) {
        console.log(err);
      }
    },
    buildCommaSeparatedString(arr) {
      let rtn = "";
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== undefined && arr[i].trim().length > 0) {
          if (rtn.length > 0) {
            rtn += ", ";
          }
          rtn += arr[i].trim();
        }
      }
      return rtn;
    },

    getContactIdFromEmail(email) {
      for (let i = 0; i < this.allContacts.length; i++) {
        if (
          this.allContacts[i].email.toLowerCase().indexOf(email.toLowerCase()) >
          -1
        ) {
          return this.allContacts[i]._id;
        }
      }
      return "";
    },
    async listConnectionNames() {
      const self = this;
      this.$google.api.client.people.people.connections
        .list({
          resourceName: "people/me",
          // 'pageSize': 10,
          // 'personFields': 'names,emailAddresses',
          personFields:
            "addresses,ageRanges,biographies,birthdays,calendarUrls,clientData,coverPhotos,emailAddresses,events,externalIds,genders,imClients,interests,locales,locations,memberships,metadata,miscKeywords,names,nicknames,occupations,organizations,phoneNumbers,photos,relations,sipAddresses,skills,urls,userDefined",
        })
        .then(async function (response) {
          var connections = response.result.connections;
          // console.log("Connections:", connections);
          if (connections && connections.length > 0) {
            self.separateSuccessAndErrorsFromGmail(connections);

            self.loading = true;
            self.loadingText = "Loading Contacts...";
            await self.fetchAllContacts();
            self.loading = false;
            self.showImportContactsPopup = true;
          } else {
            // console.log('No contacts imported.');
          }
        });
    },

    // Get an access token and call graphGetSelf & graphGetPhoto
    async fetchGraphDetails() {
      const self = this;
      console.log("Graph Apis called");
      if (
        !this.outlookUser ||
        this.outlookUser.username == "demo@example.net"
      ) {
        return;
      }
      try {
        self.loading = true;
        self.loadingText = "Loading Contacts...";
        const data = await graph.getContacts();
        this.primaryHeaders = [];
        this.primaryHeaders.push("contact_type");
        this.primaryHeaders.push("first_name");
        this.primaryHeaders.push("last_name");
        this.primaryHeaders.push("email");
        this.primaryHeaders.push("phone_number");
        this.primaryHeaders.push("address");
        this.primaryHeaders.push("company_name");
        this.primaryHeaders.push("title");
        this.uploadedCSVDataResult = [];
        this.successfullCSVData = [];
        this.errorfullCSVData = [];

        await data.forEach((contact) => {
          let object = {
            first_name: `${contact.givenName}`,
            last_name: contact.surname,
            email: contact.emailAddresses[0].address,
            phone_number: contact.mobilePhone ? contact.mobilePhone : "",
            address:
              contact.homeAddress && contact.homeAddress.postalCode
                ? `${contact.homeAddress.street} ${contact.homeAddress.city} ${contact.homeAddress.state} ${contact.homeAddress.countryOrRegion} ${contact.homeAddress.postalCode}`
                : "",
            company_name: contact.companyName ? contact.companyName : "",
            title: contact.jobTitle,
            // contact_type:
            //   this.filter_contact_type != "" &&
            //   this.filter_contact_type != "Receiver"
            //     ? this.filter_contact_type
            //     : "Receiver",
            contact_type:
              this.filter_contact_type != ""
                ? // &&
                  // this.filter_contact_type != "Receiver"
                  this.filter_contact_type
                : this.getStandardContactType,

            // aContactForRow.contact_type = this.getStandardContactType
          };
          this.uploadedCSVDataResult.push(object);
        });
        console.log("contacts", this.uploadedCSVDataResult);
        this.showImportContactsOutlookPopup = true;
        this.separateSuccessAndErrors();
        self.loading = false;

        // console.log(await graph.getContacts())
      } catch (err) {
        this.error = err;
        console.log(err);
      }
    },
  },
  watch: {
    value: {
      handler(after) {
        this.input = after;
      },
      immediate: true,
    },
    search_string: function (val) {
      this.search_string = val;
      this.fetchContacts();
    },
    // If outlookUser changes, make calls to Graph API
    outlookUser: async function () {
      this.fetchGraphDetails();
    },
  },
  beforeDestroy() {
    this.$store.commit(
      "navigationOpen/setCurrentMenu",
      {},
      {
        root: true,
      }
    );
    //getContactDeleteStatus
    this.$store.commit("contacts/setAllContacts", null, { root: true });
    this.$store.commit("contacts/setContactDeleteStatus", null, { root: true });
    this.$store.commit("contacts/setContact", null, { root: true });
    this.$store.commit("contactTypes/setAllContactTypes", null, {
      root: true,
    });
  },
};
</script>

<style lang="scss">
body.modal-open {
  height: 100vh;
  //overflow-y: hidden;
}

.import-dropdown-btn {
  margin-left: 5px;

  .el-button {
    height: 100%;
    font-size: 14px;

    span {
      padding: 0 10px;
    }
  }
}

.import-dropdown-btn-menu {
  .el-dropdown-menu__item {
    display: flex;

    img {
      width: 25px;
      height: 25px;
      padding: 3px;
      background: #f7f7f7;
      border-radius: 10px;
    }

    span {
      margin-top: -2px;
      margin-left: 10px;
    }
  }
}

.importing-result-popup {
  .el-dialog__body {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.el-table__empty-text {
  width: 100%;
}
</style>
<style lang="scss" scoped>
.tab-success {
  .el-scrollbar__thumb {
    width: 20px;
  }
}

.tab-error {
  .el-scrollbar__thumb {
    width: 20px;
  }
}

.import-contacts-table {
  min-height: 350px;
}

.import-contacts-table-wrapper {
  display: inline-block;

  .el-table {
    display: inline-block;

    .el-table__body-wrapper {
      display: inline-block !important;
    }
  }
}

.top-search-condition-bar-type-two {
  @media (max-width: 991.98px) {
    display: flex !important;
    flex-direction: column !important;

    .actions-wrapper {
      display: flex !important;
      flex-wrap: wrap !important;

      .import-contact {
        padding: 6px !important;
      }

      button {
        margin-top: 5px;
      }

      .filter-contact-type {
        margin-top: 5px;
      }
    }

    h3 {
      width: 100%;
      margin-left: 10px;
    }
  }

  .actions-wrapper {
    .filter-contact-type {
      // margin-left: 5px;
      //margin-right: 10px;
      width: 15em;
    }

    .import-contact {
      margin-left: 10px !important;
      padding: 6px 20px;
      height: 40px;
      width: 120px;
    }
  }
}

.indent {
  margin-left: 20px !important;
}

.error-details-title {
  margin-top: 20px !important;
  margin-bottom: 5px !important;
}

.error-details {
  color: red;
  font-style: italic;
}

.result-row {
  text-align: center !important;
}

.mrg {
  margin-left: 55%;
  border-radius: 2px;
}

.ntg {
  background: #2f80ed;
  color: #ffffff !important;
  border-radius: 2px;
}

.all-documents-view {
  .title {
    letter-spacing: 0.019em;
  }

  // .icon-block {
  .initial {
    grid-template-columns: 35px auto;

    .icon {
      border: 1px solid #ffa9a9;
      text-align: center;
      border-radius: 30px;
      padding: 5px;
      background-color: #f1494910;

      .initial-text {
        font-size: 0.8em;

        span {
          color: #f14949;
        }
      }
    }
  }

  // }
  body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    // background-color: #F0AD4E;
  }

  label {
    position: relative;
    display: inline-block;
    background-color: #fff;
    padding: 5px 10px;
    transition: all 1s ease;
    border-radius: 6px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);

    &::after {
      content: "";
      display: block;
      height: 2px;
      width: 80%;
      // background-color: #f754a2;
      transition: all 1s ease 0.5s;
    }

    input {
      transition: width 1s ease, opacity 0.5s ease 0.5s;
      //opacity: 0.5;
      width: 180px;
      height: 18px;
      border: 0;
      outline: none;
    }

    i {
      position: absolute;
      top: 11px;
      right: 11px;
      border-color: #333;

      cursor: pointer;
    }

    &[data-state="close"] {
      border-radius: 6px;
      padding: 2px;
      transition: all 0.5s ease;

      &::after {
        width: 0%;
        transition: all 0.2s ease;
      }

      i {
        pointer-events: none;
      }

      input {
        width: 28px;
        height: 25px;
        border-radius: 4px;
        opacity: 0;
        cursor: pointer;
        transition: opacity 0.5s ease, width 1s ease;
        -webkit-appearance: none;
      }
    }
  }

  .circle {
    position: relative;
    padding-left: 20px;
    text-transform: capitalize;
    font-size: 14px;

    &::after {
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      height: 10px;
      width: 10px;
      display: inline-block;
      border-radius: 50%;
    }

    &.completed::after {
      background: #08ad36;
    }

    &.draft::after {
      background: #c4c4c4;
    }

    &.viewed::after {
      background: #2f80ed;
    }
  }
}

.DialogueStyle {
  margin-top: 50px;
}
.top-search-condition-bar-type-two {
  .first-line,
  .second-line {
    display: flex;
    align-items: center; 
  }
}
.top-search-condition-bar-type-two {
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 15px !important;
  }
  .search-icon-wrapper {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.search-icon-wrapper i {
  position: absolute;
  top: 50%;
  right: 10px; 
  transform: translateY(-50%);
  font-size: 13px;
  font-weight: bold;
}
.el-button--mini {
  font-size: 13px;
  margin: 10px;
  padding: 9px;
  border-radius:5px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

  .search-icon-wrapper {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.search-icon-wrapper i {
  position: absolute;
  top: 50%;
  right: 10px; 
  transform: translateY(-50%);
  font-size: 13px;
  font-weight: bold;
}
.el-button--mini {
  font-size: 13px;
  margin: 10px;
  padding: 9px;
  border-radius:5px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.el-dropdown-link,
.filter-contact-type,
.create-btn {
  margin-right: 5px; 
}
  .contacts-page {
    &.actions-wrapper {
      justify-content: end;

      .create-btn {
          margin-left: 1px !important;
          transition: transform 0.3s ease-in-out; /* Add transition for smooth scaling */
      }
      .el-dropdown-link{
        margin:10px;
        padding:auto;
      }

      .create-btn:hover {
        transform: scale(1.03); /* Scale up by 10% when hovered */
      }
      .filter-contact-type {
        margin-left: 5px !important;
      }

      .search-icon-wrapper {
        height: 32px !important;
        padding-bottom: 0px;
      }

      @media (max-width: 1166.98px) {
        display: flex;
        // flex-direction: column !important;

        .first-line {
            display: flex;
            align-items: center; 
            justify-content: center;

          button {
            margin-left: 10px !important;
            padding-left: 10px !important;
            padding-right: 10px !important;
          }

          //.search-icon-wrapper {
          //}
        }

        .second-line {
          margin-left:15px;
          .filter-contact-type {
            margin-left: 0px !important;
            width: 120px;
          }

          .import-dropdown-btn {
            margin-left: 5px !important;

            button {
              padding: 8px !important;
            }
          }

          .create-btn {
            padding: 9px !important;
            margin-right: 9px !important;
            width: 12em;
          }
        }
      }
    }
  }
}

.contacts-list-table {
  .action-buttons {
    display: flex;
    flex-direction: row !important;

    & > li {
      margin-right: 0px !important;
      margin-left: 0px !important;

      & > button {
        margin-right: 3px !important;
        margin-left: 0px !important;
      }
    }
/*
    .first-button {
    }

    .second-button {
    }

    .third-button {
    }
    */
  }
}

.avatar-icon-in-table {
  min-height: 35px !important;
}

::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: auto;
  scrollbar-width: thin;
}

.all-documents-view .icon-block {
    display: grid;
    gap: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px !important;
}
.contacts-list{
  margin-top: -25px;
}
</style>
<style lang="scss">
.download-csv-button-wrapper {
  @media (max-width: 400px) {
    flex-direction: column !important;
    align-items: end;
  }

  .download-csv {
    width: 150px;
    height: 35px;
    margin-right: 6px !important;
  }
}

.alertPopupStyle {
  font-weight: 500;
  font-family: "inter", sans-serif;
}
.labelStyles{
  font-size : 14px;
  font-weight:bold;
}
.sidemenu {
  height: 200px;
  overflow-y: scroll;
  overflow-x: scroll;
  // border-top: 1px ridge;
  // border-left: 1px ridge;
  // border-bottom: 1px ridge;
  margin-right: 5px;
}
.sidemenu::-webkit-scrollbar {
  width: 0.7em;
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.el-button:focus, .el-button:hover {
  color: var(--primary-color);
  border-color: var(--primary-color);
  background-color: var(--primary-contrast-color);
}
.el-button.is-plain:focus, .el-button.is-plain:hover {
  background:var(--primary-contrast-color);
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.el-button--primary:hover{
  background:var(--primary-color);
  border-color: var(--primary-color);
  color: var(--primary-contrast-color);
}
.create-contact{
  position:relative;
  bottom:3px;
}
</style>
